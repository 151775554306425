import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from 'apps/kuiper/src/environments/environment';
import { AppStorage } from '../../enums/app-storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = environment.baseUrl;
  isLoggedIn = signal<boolean>(false);
  constructor(private http: HttpClient) {
    this.isLoggedIn.set(this.isAuthenticated());
  }

  login(form: { email: string; password: string }) {
    return this.http.post(
      `${this.baseUrl}/api/v1/application/auth/login`,
      form
    );
  }

  isAuthenticated() {
    return !!localStorage.getItem(AppStorage.token);
  }
}
